export const GetUniqueId = {
  data: () => ({}),
  methods: {
    getUniqueIdMethod() {
      let uniqueId = localStorage.getItem("uniqueId");
      console.log("check Unique ID", localStorage.getItem("uniqueId"));
      if (!uniqueId) {
        uniqueId = this.generateUniqueId();
        localStorage.setItem("uniqueId", uniqueId);
      }
      return localStorage.getItem("uniqueId");
    },
    generateUniqueId() {
      if (window.crypto) {
        const array = new Uint32Array(1);
        console.log('check Crypto Ayyaa',array)
        window.crypto.getRandomValues(array);
        console.log('check Crypto Ayya22',array[0].toString(36).substring(2, 11))
        return array[0].toString(36).substring(2, 11);
      } 
      },
  },
};
